import { EOS, Config } from "eosjs"
import * as Bluebird from "bluebird"

// BUGBUG FIX
type ScatterIdentity = {
  hash: string,
  name: string,
  kyc: boolean,
  publicKey: string
}

enum PersonalRequiredFields {
  Firstname = "firstname",
  Lastname = "lastname",
  Email = "email",
  Birthdate = "birthdate"
}

enum LocationRequiredFields {
  Phone = "phone",
  Address = "address",
  City = "city",
  State = "state",
  Country = "country",
  Zipcode = "zipcode"
}

type EosNetworkConfig = {
  blockchain: "eos",
  host: string,
  port: number
}

type EthereumNetworkConfig = {
  blockchain: "eth",
  chainId: string
}

type RequiredIdentityFields = {
  personal?: Array<PersonalRequiredFields>,
  location?: Array<LocationRequiredFields>,
  accounts?: Array<EosNetworkConfig | EthereumNetworkConfig>
}

export enum Blockchain {
  Eos = "eos"
}

export interface Scatter {
  getIdentity(requiredFields?: RequiredIdentityFields): ScatterIdentity
  eos(network: Network, constructor: (config: Config) => EOS, config?: {[K in keyof Config]?: Config[K] }): EOS
  suggestNetwork(network: Network): Promise<void>
}

export type Network = {
  blockchain: string,
  host: string | null // ( or null if endorsed chainId )
  port: number | null, // ( or null if defaulting to 80 )
  chainId?: number | string | null, // Or null to fetch automatically ( takes longer )
}

export const loadScatter = async (): Bluebird<Scatter> => {
  return new Bluebird<Scatter>((resolve, reject) => {
    document.addEventListener("scatterLoaded", scatterExtension => {
      resolve((<Window & { scatter : Scatter }>window).scatter)
    })
  })
}
