'use strict';

var ByteBuffer = require('bytebuffer');

/**
  @class Struct

  @arg {object} config.override = {
    'Message.data.appendByteBuffer': ({fields, object, b}) => {..}
  }
  Rare cases where specialized serilization is needed (ex A Message object has
  'type' and 'data' fields where object.type === 'transfer' can define
  serialization time Struct needed for 'data' .. This saves complexity for the
  end-user's working with json.  See override unit test.
*/
module.exports = function (name) {
  var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : { debug: false };

  config = Object.assign({ override: {} }, config);
  var fields = {};
  var fieldOne = void 0,
      fieldOneName = void 0;

  return {
    compare: function compare(a, b) {
      var v1 = a[fieldOneName];
      var v2 = b[fieldOneName];

      if (!fieldOne || !fieldOne.compare) {
        return v1 > v2 ? 1 : v1 < v2 ? -1 : 0;
      }

      return fieldOne.compare(v1, v2);
    },


    /** @private */
    add: function add(fieldName, type) {
      fields[fieldName] = type;
      if (fieldOne == null) {
        fieldOne = type;
        fieldOneName = fieldName;
      }
    },


    // Complete list of fields, after resolving "base" inheritance
    fields: fields,

    fromByteBuffer: function fromByteBuffer(b) {
      var object = {};
      var field = null;
      try {
        for (field in fields) {
          var type = fields[field];
          try {
            var o1 = b.offset;
            if (field === '') {
              // structPtr
              object = type.fromByteBuffer(b, config);
            } else {
              var fromByteBuffer = config.override[name + '.' + field + '.fromByteBuffer'];
              if (fromByteBuffer) {
                fromByteBuffer({ fields: fields, object: object, b: b, config: config });
              } else {
                object[field] = type.fromByteBuffer(b, config);
              }
            }
            if (config.debug) {
              if (type.struct) {
                console.error(type.struct);
              } else {
                var value = void 0;
                try {
                  // human readable text
                  value = type.toObject(field === '' ? object : object[field], config);
                } catch (error) {
                  // console.error('fromByteBuffer debug error:', error)
                  value = '';
                }
                var _b = b.copy(o1, b.offset);
                console.error('fromByteBuffer', name + '.' + field, '\'' + value + '\'', _b.toHex());
              }
            }
          } catch (e) {
            console.error(e + ' in ' + name + '.' + field);
            b.printDebug();
            throw e;
          }
        }
      } catch (error) {
        error.message += ' in ' + name + '.' + field;
        throw error;
      }
      return object;
    },
    appendByteBuffer: function appendByteBuffer(b, object) {
      var field = null;
      try {
        for (field in fields) {
          var type = fields[field];
          if (field === '') {
            // structPtr
            type.appendByteBuffer(b, object);
          } else {
            var appendByteBuffer = config.override[name + '.' + field + '.appendByteBuffer'];
            if (appendByteBuffer) {
              appendByteBuffer({ fields: fields, object: object, b: b });
            } else {
              type.appendByteBuffer(b, object[field]);
            }
          }
        }
      } catch (error) {
        try {
          error.message += ' ' + name + '.' + field + ' = ' + JSON.stringify(object[field]);
        } catch (e) {
          // circular ref
          error.message += ' ' + name + '.' + field + ' = ' + object[field];
        }
        throw error;
      }
    },
    fromObject: function fromObject(serializedObject) {
      var fromObject_struct = config.override[name + '.fromObject'];
      if (fromObject_struct) {
        var ret = fromObject_struct(serializedObject);
        if (ret != null) {
          return ret;
        }
      }

      var result = {};
      var field = null;
      try {
        for (field in fields) {
          // if(config.debug) {
          //   console.error(name, field, '(fromObject)')
          // }
          var type = fields[field];
          if (field === '') {
            // structPtr
            var object = type.fromObject(serializedObject);
            Object.assign(result, object);
          } else {
            var fromObject = config.override[name + '.' + field + '.fromObject'];
            if (fromObject) {
              fromObject({ fields: fields, object: serializedObject, result: result });
            } else {
              var value = serializedObject[field];
              var _object = type.fromObject(value);
              result[field] = _object;
            }
          }
        }
      } catch (error) {
        error.message += ' ' + name + '.' + field;
        throw error;
      }

      return result;
    },
    toObject: function toObject() {
      var serializedObject = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var toObject_struct = config.override[name + '.toObject'];
      if (toObject_struct) {
        var ret = toObject_struct(serializedObject);
        if (ret != null) {
          return ret;
        }
      }

      var result = {};
      var field = null;
      try {
        // if (!fields) { return result }

        for (field in fields) {
          var type = fields[field];

          var toObject = config.override[name + '.' + field + '.toObject'];
          if (toObject) {
            toObject({ fields: fields, object: serializedObject, result: result, config: config });
          } else {
            if (field === '') {
              // structPtr
              var object = type.toObject(serializedObject, config);
              Object.assign(result, object);
            } else {
              var _object2 = type.toObject(serializedObject ? serializedObject[field] : null, config);
              result[field] = _object2;
            }
          }

          if (config.debug) {
            try {
              var b = new ByteBuffer(ByteBuffer.DEFAULT_CAPACITY, ByteBuffer.LITTLE_ENDIAN);
              if (serializedObject != null) {
                var value = serializedObject[field];
                if (value) {
                  var appendByteBuffer = config.override[name + '.' + field + '.appendByteBuffer'];
                  if (toObject && appendByteBuffer) {
                    appendByteBuffer({ fields: fields, object: serializedObject, b: b });
                  } else {
                    type.appendByteBuffer(b, value);
                  }
                }
              }
              b = b.copy(0, b.offset);
              console.error('toObject', name + '.' + field, '\'' + result[field] + '\'', b.toHex());
            } catch (error) {
              // work-around to prevent debug time crash
              error.message = name + '.' + field + ' ' + error.message;
              console.error(error);
            }
          }
        }
      } catch (error) {
        error.message += ' ' + name + '.' + field;
        throw error;
      }
      return result;
    }
  };
};