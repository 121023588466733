import app from "./app/elm"
import * as Scatter from "./clients/scatter"
import * as EOS from "eosjs"
import * as Bluebird from "bluebird"

const networkConfig: Scatter.Network = {
  blockchain: "eos",
  host: "127.0.0.1",
  port: 8888,
  chainId: "b443fe96d85d6502ea4ea3b9d0e7a613570e5483a66d5eac7cd8c12d07f476ae"
}

const init = async() => {
  const scatter = await Scatter.loadScatter().timeout(5000).catch(Bluebird.TimeoutError, error => app.ports.missingExtension.send(null))
  const eos = scatter && getEos(scatter)
  const identity = scatter && await scatter.getIdentity({ accounts: [{ blockchain: "eos", host:'127.0.0.1', port:8888 }]} )

  if (identity) {
    app.ports.identityReceived.send({ name: identity.name })
  }

  const contract = await eos.contract("johnil")

  const tableRows = await eos.getTableRows({ json: true, code: "johnil", scope: "owner", table: "mon", table_key: "", lower_bound: null, upper_bound: null, limit: 5 })
  
  
}

const getEos = (scatter: Scatter.Scatter): EOS.EOS => scatter.eos(networkConfig, EOS.Localnet)

init()