'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var BN = require('bn.js');

var _require = require('bytebuffer'),
    Long = _require.Long;

var assert = require('assert');

var types = {
  bytes: function bytes() {
    return [bytebuf];
  },
  string: function string() {
    return [_string];
  },
  vector: function vector(type) {
    var sorted = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    return [_vector, { type: type, sorted: sorted }];
  },
  optional: function optional(type) {
    return [_optional, { type: type }];
  },
  time: function time() {
    return [_time2];
  },
  map: function map(annotation) {
    return [_map, { annotation: annotation }];
  },
  static_variant: function static_variant(types) {
    return [_static_variant, { types: types }];
  },

  fixed_string16: function fixed_string16() {
    return [_string, { maxLen: 16 }];
  },
  fixed_string32: function fixed_string32() {
    return [_string, { maxLen: 32 }];
  },

  fixed_bytes16: function fixed_bytes16() {
    return [bytebuf, { len: 16 }];
  },
  fixed_bytes20: function fixed_bytes20() {
    return [bytebuf, { len: 20 }];
  },
  fixed_bytes28: function fixed_bytes28() {
    return [bytebuf, { len: 28 }];
  },
  fixed_bytes32: function fixed_bytes32() {
    return [bytebuf, { len: 32 }];
  },
  fixed_bytes33: function fixed_bytes33() {
    return [bytebuf, { len: 33 }];
  },
  fixed_bytes64: function fixed_bytes64() {
    return [bytebuf, { len: 64 }];
  },
  fixed_bytes65: function fixed_bytes65() {
    return [bytebuf, { len: 65 }];
  },

  uint8: function uint8() {
    return [intbuf, { bits: 8 }];
  },
  uint16: function uint16() {
    return [intbuf, { bits: 16 }];
  },
  uint32: function uint32() {
    return [intbuf, { bits: 32 }];
  },
  uint64: function uint64() {
    return [intbuf, { bits: 64 }];
  },
  uint128: function uint128() {
    return [bnbuf, { bits: 128 }];
  },
  uint224: function uint224() {
    return [bnbuf, { bits: 224 }];
  },
  uint256: function uint256() {
    return [bnbuf, { bits: 256 }];
  },
  uint512: function uint512() {
    return [bnbuf, { bits: 512 }];
  },

  varuint32: function varuint32() {
    return [intbuf, { bits: 32, variable: true }];
  },

  int8: function int8() {
    return [intbuf, { signed: true, bits: 8 }];
  },
  int16: function int16() {
    return [intbuf, { signed: true, bits: 16 }];
  },
  int32: function int32() {
    return [intbuf, { signed: true, bits: 32 }];
  },
  int64: function int64() {
    return [intbuf, { signed: true, bits: 64 }];
  },
  int128: function int128() {
    return [bnbuf, { signed: true, bits: 128 }];
  },
  int224: function int224() {
    return [bnbuf, { signed: true, bits: 224 }];
  },
  int256: function int256() {
    return [bnbuf, { signed: true, bits: 256 }];
  },
  int512: function int512() {
    return [bnbuf, { signed: true, bits: 512 }];
  },

  varint32: function varint32() {
    return [intbuf, { signed: true, bits: 32, variable: true }];
  },

  float64: function float64() {
    return [float, { bits: 64 }];
  }

  /*
    @arg {SerializerConfig} config
    @return {object} {[typeName]: function(args)}
  */
};module.exports = function (config) {
  config = Object.assign({ defaults: false, debug: false, customTypes: {} }, config);

  var allTypes = Object.assign({}, types, config.customTypes);

  var createTypeReducer = function createTypeReducer(baseTypes) {
    return function (customTypes, name) {
      customTypes[name] = function () {
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        var type = createType(name, config, args, baseTypes, allTypes, customTypes);
        return type;
      };
      return customTypes;
    };
  };

  var baseTypes = Object.keys(types).reduce(createTypeReducer(), {});

  var customTypes = Object.keys(config.customTypes || {}).reduce(createTypeReducer(baseTypes), {});

  return Object.assign({}, baseTypes, customTypes, { config: config });
};

/**
    @args {string} typeName - matches types[]
    @args {string} config - Additional arguments for types
*/
function createType(typeName, config, args, baseTypes, allTypes, customTypes) {
  var Type = baseTypes ? allTypes[typeName] : types[typeName];

  var _Type = Type.apply(undefined, _toConsumableArray(args)),
      _Type2 = _slicedToArray(_Type, 2),
      fn = _Type2[0],
      _Type2$ = _Type2[1],
      v = _Type2$ === undefined ? {} : _Type2$;

  var validation = Object.assign(v, config);
  validation.typeName = typeName;
  var type = fn(validation, baseTypes, customTypes);
  type.typeName = typeName;
  return type;
}

var _map = function _map(validation) {
  var _validation$annotatio = _slicedToArray(validation.annotation, 2),
      type1 = _validation$annotatio[0],
      type2 = _validation$annotatio[1];

  if (!isSerializer(type1)) {
    throw new TypeError('map<type1, > unknown');
  }
  if (!isSerializer(type2)) {
    throw new TypeError('map<, type2> unknown');
  }

  return {
    fromByteBuffer: function fromByteBuffer(b) {
      var size = b.readVarint32();
      var result = {};
      for (var i = 0; i < size; i++) {
        result[type1.fromByteBuffer(b)] = type2.fromByteBuffer(b);
      }
      if (validation.debug) {
        console.log('0x' + size.toString(16), '(map.fromByteBuffer length)', result);
      }
      return result;
    },
    appendByteBuffer: function appendByteBuffer(b, value) {
      validate(value, validation);
      var keys = Object.keys(value);
      b.writeVarint32(keys.length);
      if (validation.debug) {
        console.log('0x' + keys.length.toString(16), '(map.appendByteBuffer length)', keys);
      }
      // if(sorted === true) {
      //   value = sortKeys(type1, Object.assign({}, value))
      // }
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = keys[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var o = _step.value;

          var value2 = value[o];
          type1.appendByteBuffer(b, o);
          type2.appendByteBuffer(b, value2);
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    },
    fromObject: function fromObject(value) {
      validate(value, validation);
      var result = {};
      // if(sorted === true) {
      //   value = sortKeys(type1, Object.assign({}, value))
      // }
      for (var o in value) {
        result[type1.fromObject(o)] = type2.fromObject(value[o]);
      }
      return result;
    },
    toObject: function toObject(value) {
      if (validation.defaults && value == null) {
        return _defineProperty({}, type1.toObject(null), type2.toObject(null));
      }
      validate(value, validation);
      var result = {};
      // if(sorted === true) {
      //   value = sortKey(type1, Object.assign({}, value))
      // }
      for (var o in value) {
        result[type1.toObject(o)] = type2.toObject(value[o]);
      }
      return result;
    }
  };
};

var _static_variant = function _static_variant(validation) {
  var types = validation.types;

  return {
    fromByteBuffer: function fromByteBuffer(b) {
      var typePosition = b.readVarint32();
      var type = types[typePosition];
      if (validation.debug) {
        console.error('static_variant id ' + typePosition + ' (0x' + typePosition.toString(16) + ')');
      }
      assert(type, 'static_variant invalid type position ' + typePosition);
      return [typePosition, type.fromByteBuffer(b)];
    },
    appendByteBuffer: function appendByteBuffer(b, object) {
      assert(Array.isArray(object) && object.length === 2, 'Required tuple');
      var typePosition = object[0];
      var type = types[typePosition];
      assert(type, 'type ' + typePosition);
      b.writeVarint32(typePosition);
      type.appendByteBuffer(b, object[1]);
    },
    fromObject: function fromObject(object) {
      assert(Array.isArray(object) && object.length === 2, 'Required tuple');
      var typePosition = object[0];
      var type = types[typePosition];
      assert(type, 'type ' + typePosition);
      return [typePosition, type.fromObject(object[1])];
    },
    toObject: function toObject(object) {
      if (validation.defaults && object == null) {
        return [0, types[0].toObject(null, debug)];
      }
      assert(Array.isArray(object) && object.length === 2, 'Required tuple');
      var typePosition = object[0];
      var type = types[typePosition];
      assert(type, 'type ' + typePosition);
      return [typePosition, type.toObject(object[1])];
    }
  };
};

var _vector = function _vector(validation) {
  var type = validation.type,
      sorted = validation.sorted;

  if (!isSerializer(type)) {
    throw new TypeError('vector type should be a serializer');
  }

  return {
    fromByteBuffer: function fromByteBuffer(b) {
      var size = b.readVarint32();
      if (validation.debug) {
        console.log('fromByteBuffer vector length', size, '(0x' + size.toString(16) + ')');
      }
      var result = [];
      for (var i = 0; i < size; i++) {
        result.push(type.fromByteBuffer(b));
      }
      return result;
    },
    appendByteBuffer: function appendByteBuffer(b, value) {
      validate(value, validation);
      b.writeVarint32(value.length);
      if (sorted === true) {
        value = sort(type, Object.assign([], value));
      }
      if (validation.debug) {
        console.log('0x' + value.length.toString(16), '(vector.appendByteBuffer length)', value);
      }
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = value[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var o = _step2.value;

          type.appendByteBuffer(b, o);
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }
    },
    fromObject: function fromObject(value) {
      validate(value, validation);
      var result = [];
      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        for (var _iterator3 = value[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var o = _step3.value;

          result.push(type.fromObject(o));
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return) {
            _iterator3.return();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }

      if (sorted === true) {
        result = sort(type, Object.assign([], result));
      }
      return result;
    },
    toObject: function toObject(value) {
      if (validation.defaults && value == null) {
        return [type.toObject(value)];
      }
      validate(value, validation);
      if (sorted === true) {
        value = sort(type, Object.assign([], value));
      }
      var result = [];
      var _iteratorNormalCompletion4 = true;
      var _didIteratorError4 = false;
      var _iteratorError4 = undefined;

      try {
        for (var _iterator4 = value[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
          var o = _step4.value;

          result.push(type.toObject(o));
        }
      } catch (err) {
        _didIteratorError4 = true;
        _iteratorError4 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion4 && _iterator4.return) {
            _iterator4.return();
          }
        } finally {
          if (_didIteratorError4) {
            throw _iteratorError4;
          }
        }
      }

      return result;
    }
  };
};

var _optional = function _optional(validation) {
  var type = validation.type;

  if (!isSerializer(type)) {
    throw new TypeError('optional parameter should be a serializer');
  }

  return {
    fromByteBuffer: function fromByteBuffer(b) {
      if (!(b.readUint8() === 1)) {
        return null;
      }
      return type.fromByteBuffer(b);
    },
    appendByteBuffer: function appendByteBuffer(b, value) {
      if (value != null) {
        b.writeUint8(1);
        type.appendByteBuffer(b, value);
      } else {
        b.writeUint8(0);
      }
    },
    fromObject: function fromObject(value) {
      if (value == null) {
        return null;
      }
      return type.fromObject(value);
    },
    toObject: function toObject(value) {
      // toObject is only null save if defaults is true
      var resultValue = void 0;
      if (value == null && !validation.defaults) {
        resultValue = null;
      } else {
        resultValue = type.toObject(value);
      }
      return resultValue;
    }
  };
};

var intbufType = function intbufType(_ref2) {
  var _ref2$signed = _ref2.signed,
      signed = _ref2$signed === undefined ? false : _ref2$signed,
      bits = _ref2.bits,
      variable = _ref2.variable;
  return variable ? 'Varint' + bits + (signed ? 'ZigZag' : '') : '' + (signed ? 'Int' : 'Uint') + bits;
};

var intbuf = function intbuf(validation) {
  return {
    fromByteBuffer: function fromByteBuffer(b) {
      var value = b['read' + intbufType(validation)]();
      return Long.isLong(value) ? value.toString() : value;
    },
    appendByteBuffer: function appendByteBuffer(b, value) {
      // validateInt(value, validation)
      // value = typeof value === 'string' ? Long.fromString(value) : value
      b['write' + intbufType(validation)](value);
    },
    fromObject: function fromObject(value) {
      validateInt(value, validation);
      // if(validation.bits > 53 && typeof value === 'number')
      //     value = String(value)

      return value;
    },
    toObject: function toObject(value) {
      if (validation.defaults && value == null) {
        return validation.bits > 53 ? '0' : 0;
      }

      validateInt(value, validation);
      // if(validation.bits > 53 && typeof value === 'number')
      //     value = String(value)

      return Long.isLong(value) ? value.toString() : value;
    }
  };
};

/** Big Numbers (> 64 bits) */
var bnbuf = function bnbuf(validation) {
  var _validation$signed = validation.signed,
      signed = _validation$signed === undefined ? false : _validation$signed,
      bits = validation.bits;

  var size = bits / 8;
  return {
    fromByteBuffer: function fromByteBuffer(b) {
      var bcopy = b.copy(b.offset, b.offset + size);
      b.skip(size);

      var bn = new BN(bcopy.toHex(), 'hex');
      var buf = bn.toArrayLike(Buffer, 'le', size); // convert to little endian
      bn = new BN(buf.toString('hex'), 'hex');
      if (signed) {
        bn = bn.fromTwos(bits);
      }
      var value = bn.toString();
      validateInt(value, validation);
      return bits > 53 ? value : bn.toNumber();
    },
    appendByteBuffer: function appendByteBuffer(b, value) {
      validateInt(value, validation);
      var bn = new BN(value);
      if (signed) {
        bn = bn.toTwos(bits);
      }
      var buf = bn.toArrayLike(Buffer, 'le', size);
      b.append(buf.toString('binary'), 'binary');
    },
    fromObject: function fromObject(value) {
      validateInt(value, validation);
      return value;
    },
    toObject: function toObject(value) {
      if (validation.defaults && value == null) {
        return validation.bits > 53 ? '0' : 0;
      }
      validateInt(value, validation);
      return value;
    }
  };
};

var floatPoint = require('ieee-float');

var float = function float(validation) {
  var bits = validation.bits;

  // assert(bits === 32 || bits === 64, 'unsupported float bit size: ' + bits)

  var sizeName = bits === 32 ? 'Float' : bits === 64 ? 'Double' : null;
  assert(sizeName, 'unsupported float bit size: ' + bits);
  var size = bits / 8;

  return {
    fromByteBuffer: function fromByteBuffer(b) {
      var bcopy = b.copy(b.offset, b.offset + size);
      b.skip(size);
      var fb = Buffer.from(bcopy.toBinary(), 'binary');
      return floatPoint['read' + sizeName + 'LE'](fb);
    },
    appendByteBuffer: function appendByteBuffer(b, value) {
      var output = [];
      floatPoint['write' + sizeName + 'LE'](output, value);
      b.append(output);
    },
    fromObject: function fromObject(value) {
      return value;
    },
    toObject: function toObject(value) {
      if (validation.defaults && value == null) {
        return 0.0;
      }
      return value;
    }
  };
};

var bytebuf = function bytebuf(validation) {
  var _bytebuf = {
    fromByteBuffer: function fromByteBuffer(b) {
      var len = validation.len;

      var bCopy = void 0;
      if (len == null) {
        var lenPrefix = b.readVarint32();
        bCopy = b.copy(b.offset, b.offset + lenPrefix);
        b.skip(lenPrefix);
      } else {
        bCopy = b.copy(b.offset, b.offset + len);
        b.skip(len);
      }
      return Buffer.from(bCopy.toBinary(), 'binary');
    },
    appendByteBuffer: function appendByteBuffer(b, value) {
      // value = _bytebuf.fromObject(value)

      var len = validation.len;

      if (len == null) {
        b.writeVarint32(value.length);
      }
      b.append(value.toString('binary'), 'binary');
    },
    fromObject: function fromObject(value) {
      if (typeof value === 'string') {
        value = Buffer.from(value, 'hex');
      }

      validate(value, validation);
      return value;
    },
    toObject: function toObject(value) {
      var defaults = validation.defaults,
          len = validation.len;

      if (defaults && value == null) {
        return Array(len ? len + 1 : 1).join('00');
      }
      validate(value, validation);
      return value.toString('hex');
    },
    compare: function compare(a, b) {
      return Buffer.compare(a, b);
    }
  };
  return _bytebuf;
};

var _string = function _string(validation) {
  return {
    fromByteBuffer: function fromByteBuffer(b) {
      return b.readVString();
    },
    appendByteBuffer: function appendByteBuffer(b, value) {
      validate(value, validation);
      b.writeVString(value.toString());
    },
    fromObject: function fromObject(value) {
      validate(value, validation);
      return value;
    },
    toObject: function toObject(value) {
      if (validation.defaults && value == null) {
        return '';
      }
      validate(value, validation);
      return value;
    }
  };
};

var _time2 = function _time2(validation) {
  var _time = {
    fromByteBuffer: function fromByteBuffer(b) {
      return b.readUint32();
    },
    appendByteBuffer: function appendByteBuffer(b, value) {
      // if(typeof value !== "number")
      //     value = _time.fromObject(value)

      validate(value, validation);
      b.writeUint32(value);
    },
    fromObject: function fromObject(value) {
      validate(value, validation);

      if (typeof value === 'number') {
        return value;
      }

      if (value.getTime) {
        return Math.floor(value.getTime() / 1000);
      }

      if (typeof value !== 'string') {
        throw new Error('Unknown date type: ' + value);
      }

      // Chrome assumes Zulu when missing, Firefox does not
      if (typeof value === 'string' && !/Z$/.test(value)) {
        value += 'Z';
      }

      return Math.floor(new Date(value).getTime() / 1000);
    },
    toObject: function toObject(value) {
      if (validation.defaults && value == null) {
        return new Date(0).toISOString().split('.')[0];
      }

      validate(value, validation);

      // if(typeof value === "string") {
      //     if(!/Z$/.test(value))
      //         value += "Z"
      //
      //     return value
      // }

      // if(value.getTime)
      //     return value.toISOString().split('.')[0] + 'Z'

      validateInt(value, spread(validation, { bits: 32 }));
      var int = parseInt(value);
      return new Date(int * 1000).toISOString().split('.')[0];
    }
  };
  return _time;
};

var validate = function validate(value, validation) {
  if (isEmpty(value)) {
    throw new Error('Required ' + validation.typeName);
  }

  if (validation.len != null) {
    if (value.length == null) {
      throw new Error('len validation requries a "length" property');
    }

    var len = validation.len;

    if (value.length !== len) {
      throw new Error(validation.typeName + ' length ' + value.length + ' does not equal ' + len);
    }
  }

  if (validation.maxLen != null) {
    var maxLen = validation.maxLen;

    if (value.length == null) {
      throw new Error('maxLen validation requries a "length" property');
    }

    if (value.length > maxLen) {
      throw new Error(validation.typeName + ' length ' + value.length + ' exceeds maxLen ' + maxLen);
    }
  }
};

var ZERO = new BN();
var ONE = new BN('1');

function validateInt(value, validation) {
  if (isEmpty(value)) {
    throw new Error('Required ' + validation.typeName);
  }
  var _validation$signed2 = validation.signed,
      signed = _validation$signed2 === undefined ? false : _validation$signed2,
      _validation$bits = validation.bits,
      bits = _validation$bits === undefined ? 54 : _validation$bits;


  value = String(value).trim();
  if (signed && !/^-?[0-9]+$/.test(value) || !signed && !/^[0-9]+$/.test(value)) {
    throw new Error('Number format ' + validation.typeName + ' ' + value);
  }

  var max = signed ? maxSigned(bits) : maxUnsigned(bits);
  var min = signed ? minSigned(bits) : ZERO;
  var i = new BN(value);

  // console.log('i.toString(), min.toString()', i.toString(), min.toString())
  if (i.cmp(min) < 0 || i.cmp(max) > 0) {
    throw new Error('Overflow ' + validation.typeName + ' ' + value + ', ' + ('max ' + max.toString() + ', min ' + min.toString() + ', signed ' + signed + ', bits ' + bits));
  }
}

var isSerializer = function isSerializer(type) {
  return (typeof type === 'undefined' ? 'undefined' : _typeof(type)) === 'object' && typeof type.fromByteBuffer === 'function' && typeof type.appendByteBuffer === 'function' && typeof type.fromObject === 'function' && typeof type.toObject === 'function';
};

var toString = function toString(value, encoding) {
  return value == null ? value : value.toString ? value.toString(encoding) : value;
};

var sort = function sort(type, values) {
  return type.compare ? values.sort(type.compare) : // custom compare
  values.sort();
};

var spread = function spread() {
  return Object.assign.apply(Object, arguments);
};
var isEmpty = function isEmpty(value) {
  return value == null;
};

// 1 << N === Math.pow(2, N)
var maxUnsigned = function maxUnsigned(bits) {
  return new BN(1).ishln(bits).isub(ONE);
};
var maxSigned = function maxSigned(bits) {
  return new BN(1).ishln(bits - 1).isub(ONE);
};
var minSigned = function minSigned(bits) {
  return new BN(1).ishln(bits - 1).ineg();
};